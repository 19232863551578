import React, { useState } from "react";
import styled from "styled-components/macro";
import moment from "moment/min/moment-with-locales";
import esb from "elastic-builder";

import { MEDIA_MIN_LARGE } from "variables/mediaQueries";
import SearchForm from "components/Table/Search/SearchForm";
import FilterButton from "components/Ui/FilterButton";
import FilterForm from "components/Filter/FilterForm";
import Timespan from "components/Table/Filter/Timespan";
import ClearButton from "components/Table/Filter/ClearButton";

const Buttons = styled.div`
  display: flex;
  padding: 0.5rem 0;
  margin: 1.5rem auto 1.5rem;

  ${MEDIA_MIN_LARGE} {
    margin: 1.5rem 0;
  }
`;

const Label = styled.label`
  width: 100%;
  padding-bottom: 0.5rem;
  display: flex;
  align-items: center;
  white-space: nowrap;

  ${MEDIA_MIN_LARGE} {
    width: auto;
    padding-bottom: 0;
  }
`;

const DEFAULT_START_DATE = moment().subtract(1, "month").format("YYYY-MM-DD");
const DEFAULT_END_DATE = moment().format("YYYY-MM-DD");

export default ({ allDiscounts, esbQuery, setEsbQuery }) => {
  const [searchInput, setSearchInput] = useState("");
  const [defaultQuery] = useState(allDiscounts);
  const [inputError, setInputError] = useState(false);
  const [filterStartDate, setFilterStartDate] = useState(DEFAULT_START_DATE);
  const [filterEndDate, setFilterEndDate] = useState(moment().format(DEFAULT_END_DATE));

  const searchDiscounts = (evt) => {
    evt.preventDefault();
    clearFilterInput();
    searchInput.length ? setInputError(false) : setInputError(true);
    setEsbQuery(
      new esb.requestBodySearch().query(
        esb
          .queryStringQuery(
            searchInput
              .split(" ")
              .join("* ")
              .concat("*")
              .concat(" AND !(archived:1) AND !(type:VOUCHER)")
          )
          .analyzeWildcard()
      )
    );
  };

  const filterDiscountCodes = (evt) => {
    evt.preventDefault();
    let boolQuery = new esb.boolQuery()
      .must(esb.rangeQuery("startDate").lte(filterStartDate).format("yyyy-MM-dd"))
      .must(esb.rangeQuery("endDate").gte(filterEndDate).format("yyyy-MM-dd"));
    if (esbQuery._body && esbQuery._body.boolQuery) {
      boolQuery.must(esbQuery._body.boolQuery);
    }
    setEsbQuery(esb.requestBodySearch().query(boolQuery));
  };

  const clearFilter = (evt) => {
    evt.preventDefault();
    clearFilterInput();
    setEsbQuery(defaultQuery);
  };

  const clearFilterInput = () => {
    setFilterStartDate(DEFAULT_START_DATE);
    setFilterEndDate(DEFAULT_END_DATE);
  };

  return (
    <>
      <SearchForm
        placeholder="Discount code"
        inputError={inputError}
        setInputError={setInputError}
        setSearchInput={setSearchInput}
        searchFunc={searchDiscounts}
      />

      <FilterForm>
        <Timespan>
          <div>
            <Label>Start date:</Label>
            <input
              type="date"
              id="startDate"
              name="startDate"
              value={filterStartDate}
              onChange={(e) => setFilterStartDate(e.target.value)}
            />
          </div>
          <div>
            <Label>End date:</Label>
            <input
              type="date"
              id="endDate"
              name="endDate"
              value={filterEndDate}
              onChange={(e) => setFilterEndDate(e.target.value)}
            />
          </div>
        </Timespan>
        <Buttons>
          <FilterButton isLoading={false} onClick={filterDiscountCodes}>
            <i className="fal fa-sliders-h"></i> Filter
          </FilterButton>
          <ClearButton onClick={clearFilter} />
        </Buttons>
      </FilterForm>
    </>
  );
};
