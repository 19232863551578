import React from "react";
import styled from "styled-components/macro";
import { MEDIA_MIN_LARGE } from "variables/mediaQueries";

const QuickFilters = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  padding: 0;

  ${MEDIA_MIN_LARGE} {
    padding: 0 2rem;
    min-height: 8rem;
  }

  > div {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 1rem 0;
    overflow: hidden;
    height: ${(p) => (p.expanded ? "auto" : "3rem")};

    ${MEDIA_MIN_LARGE} {
      height: auto;
      justify-content: flex-start;
    }
  }
`;

export default ({ children, expanded, ...props }) => (
  <QuickFilters expanded={expanded} {...props}>
    {children}
  </QuickFilters>
);
