import React from "react";
import styled from "styled-components/macro";
import { MEDIA_MIN_LARGE } from "variables/mediaQueries";

const Toolbar = styled.div`
  width: 100%;
  height: 6rem;
  display: flex;
  align-items: center;
  border-top: 0.1rem solid ${(p) => p.theme.colors.whiteOpac};
  border-bottom: 0.1rem solid ${(p) => p.theme.colors.whiteOpac};
  padding-bottom: 0.1rem;

  ${MEDIA_MIN_LARGE} {
    height: 8rem;
    border-top: none;
  }
`;

export default ({ children, ...props }) => <Toolbar {...props}>{children}</Toolbar>;
