import React, { useState } from "react";
import esb from "elastic-builder";

import SearchForm from "components/Table/Search/SearchForm";

export default ({ setEsbQuery }) => {
  const [searchInput, setSearchInput] = useState("");
  const [inputError, setInputError] = useState(false);

  const searchDiscounts = (evt) => {
    evt.preventDefault();
    searchInput.length ? setInputError(false) : setInputError(true);
    setEsbQuery(
      new esb.requestBodySearch().query(
        esb
          .queryStringQuery(
            searchInput.split(" ").join("* ").concat("*").concat(" AND archived:0 AND type:CART")
          )
          .fields(["name^3", "templateData"])
          .analyzeWildcard()
      )
    );
  };

  return (
    <SearchForm
      placeholder="Discount rule"
      inputError={inputError}
      setInputError={setInputError}
      setSearchInput={setSearchInput}
      searchFunc={searchDiscounts}
    />
  );
};
