import gql from "graphql-tag";

const ADD_POST_PURCHASE_DISCOUNT = gql`
  mutation AddPostPurchaseDiscount($discountOrder: InputDiscountOrder!) {
    discountOrder(discountOrder: $discountOrder) {
      id
      status
      statusLog {
        status
        timestamp
      }
      customerAttribute
      orderLines {
        id
        name
        quantity
        taxRate
        totalAmount
        totalAmountWithDiscount
        totalTaxAmount
        type
        product {
          id
          name
          relatedProduct {
            id
          }
          imageUrl
          price {
            amount
            currencyUnit
          }
        }
      }
    }
  }
`;

export default ADD_POST_PURCHASE_DISCOUNT;
