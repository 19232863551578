import curveLight from "images/curve-light.svg";
import curveDark from "images/curve-dark.svg";

export const getTheme = (theme) => {
  const black = "#000000";
  const white = "#ffffff";


  const shared = {
    primary: "#47e0c2",
    primaryLight: "#91ecda",
    primaryDark: "#39b39b",
    primaryDarker: "#2b8674",
    primaryGradient:
      "background: #39b39b; background: linear-gradient(171deg, #47e0c2 0%, #39b39b 100%)",
    buttonText: "#15263e",
    yellow: "#FFE6BC",
    red: "#CC3232"
  };

  const constants = {
    black,
    white,
    background: "#0c151c",
    secondaryBackground: "#0c151c",
  };

  const light = {
    colors: {
      black: white,
      white: black,
      ...shared,
      green: "#3CD2B7",
      secondaryText: "rgba(0, 0, 0, 0.5)",
      disabledText: "rgba(0, 0, 0, 0.3)",
      whiteOpac: "rgba(0, 0, 0, 0.05)",
      background: "#F9F9F9",
      backgroundOpac: "rgba(0, 0, 0, 0.03)",
      secondaryBackground: "#fff",
      backgroundHighlight: "#f9f9f9",
      secondary: "#ccc",
      inputBorderColor: "rgba(0, 0, 0, 0.05)",
      inputBackgroundColor: "#FBFBFB",
      checkboxBorder: "rgba(0, 0, 0, 0.1)",
      greyOpac: "rgba(0, 0, 0, 0.04)",
      darkerGrey: "#999",
      tooltipBackground: "#0c151c",
      opac1: "rgba(0, 0, 0, 0.1)",
    },
    constants: { ...constants },
    curve: curveLight,
  };

  const dark = {
    colors: {
      black,
      white,
      ...shared,
      secondaryText: "rgba(255, 255, 255, 0.5)",
      disabledText: "rgba(255, 255, 255, 0.3)",
      whiteOpac: "rgba(255, 255, 255, 0.05)",
      background: "#080f14",
      backgroundOpac: "rgba(255, 255, 255, 0.03)",
      secondaryBackground: "#0c151c",
      backgroundHighlight: "#0d1921",
      secondary: "#2a4054",
      inputBorderColor: "rgba(255, 255, 255, 0.05)",
      inputBackgroundColor: "#FBFBFB",
      checkboxBorder: "rgba(255, 255, 255, 0.1)",
      greyOpac: "rgba(255, 255, 255, 0.04)",
      darkerGrey: "#999",
      tooltipBackground: "#f9f9f9",
      opac1: "rgba(255, 255, 255, 0.1)",
    },
    constants: { ...constants },
    curve: curveDark,
  };

  return theme === "light" || !theme ? light : dark;
};

export const shadow = "0 0.2rem 1.6rem 0 rgb(0 0 0 / 8%)";

// Alerts
export const alerts = {
  general: {
    background: "#777",
    highlight: "#ccc",
    text: "#fff",
  },
  error: {
    background: "#843333",
    highlight: "#9E362C",
    text: "#fff",
  },
  success: {
    background: "#335c33",
    highlight: "#fff",
    text: "#fff",
  },
};
