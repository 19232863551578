import React, { useState } from "react";
import esb from "elastic-builder";

import SearchForm from "components/Table/Search/SearchForm";

export default ({ setEsbQuery }) => {
  const [searchInput, setSearchInput] = useState("");
  const [inputError, setInputError] = useState(false);

  const searchProducts = (evt) => {
    evt.preventDefault();
    searchInput.length ? setInputError(false) : setInputError(true);
    setEsbQuery(
      new esb.requestBodySearch().query(
        esb
          .queryStringQuery(
            `${searchInput
              .split(" ")
              .join("* ")
              .concat("*")} AND type:productVariant AND !(archived:1)`
          )
          .analyzeWildcard()
      )
    );
  };

  return (
    <SearchForm
      autoComplete="off"
      placeholder="Name, ID, SKU..."
      inputError={inputError}
      setInputError={setInputError}
      setSearchInput={setSearchInput}
      searchFunc={searchProducts}
    />
  );
};
