import gql from "graphql-tag";

const ADD_OR_UPDATE_STORE = gql`
  mutation addOrUpdateStore(
    $countryCode: String!
    $languageCode: String!
    $tax: Int!
    $currencyUnit: String!
  ) {
    addOrUpdateStore(
      store: {
        countryCode: $countryCode
        languageCode: $languageCode
        tax: $tax
        currencyUnit: $currencyUnit
      }
    ) {
      countryCode
      languageCode
      tax
      currencyUnit
    }
  }
`;

export default ADD_OR_UPDATE_STORE;
