import React, { useContext, useState } from "react";
import moment from "moment-timezone";
import esb from "elastic-builder";

export const AdminContext = React.createContext({});
export const useAdminContext = () => useContext(AdminContext);

const DEFAULT_STATUS = [];
const DEFAULT_DATETIME_FROM = `${moment().subtract(1, "month").format("YYYY-MM-DD")}T00:00`;
const DEFAULT_DATETIME_TO = `${moment().format("YYYY-MM-DD")}T23:59`;
const INCLUDE_LIST = [
  { value: "created", label: "Created" },
  { value: "lastUpdated", label: "Last Updated" },
];
const ORDERS_PER_PAGE = 25;
const ALL_ORDERS = new esb.requestBodySearch()
  .sort(esb.sort("lastUpdated", "desc"))
  .size(ORDERS_PER_PAGE)
  .from(0)
  .query(
    esb
      .boolQuery()
      .must([esb.matchQuery("statusLog.status", "success")])
      .must(
        esb
          .rangeQuery("created")
          .gte(DEFAULT_DATETIME_FROM.replace("T", "-"))
          .lte(DEFAULT_DATETIME_TO.replace("T", "-"))
          .format("yyyy-MM-dd-HH:mm")
          .timeZone(moment.tz.guess())
      )
  )
  .trackTotalHits(true);

const ALL_ORDERS_FOLLOW_UP = new esb.requestBodySearch()
  .sort(esb.sort("lastUpdated", "desc"))
  .size(ORDERS_PER_PAGE)
  .from(0)
  .query(
    esb
      .boolQuery()
      .mustNot([esb.matchQuery("statusLog.status", "success")])
      .must(
        esb
          .rangeQuery("created")
          .gte(DEFAULT_DATETIME_FROM.replace("T", "-"))
          .lte(DEFAULT_DATETIME_TO.replace("T", "-"))
          .format("yyyy-MM-dd-HH:mm")
          .timeZone(moment.tz.guess())
      )
  )
  .trackTotalHits(true);

const defaultOrderFilter = {
  esbQuery: ALL_ORDERS,
  include: INCLUDE_LIST[0].value,
  filterDateTimeFrom: DEFAULT_DATETIME_FROM,
  filterDateTimeTo: DEFAULT_DATETIME_TO,
  filterStatus: DEFAULT_STATUS,
  filterStores: [],
  currentPage: 1,
  ordersPerPage: ORDERS_PER_PAGE,
};

const defaultOrderFollowUpFilter = {
  esbQuery: ALL_ORDERS_FOLLOW_UP,
  include: INCLUDE_LIST[0].value,
  filterDateTimeFrom: DEFAULT_DATETIME_FROM,
  filterDateTimeTo: DEFAULT_DATETIME_TO,
  filterStatus: DEFAULT_STATUS,
  filterStores: [],
  currentPage: 1,
  ordersPerPage: ORDERS_PER_PAGE,
};

export const AdminProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [tabs, setTabs] = useState([]);
  const [esbQueryOrders, setEsbQueryOrders] = useState(ALL_ORDERS);
  const [esbQueryFollowUp, setEsbQueryFollowUp] = useState(ALL_ORDERS_FOLLOW_UP);
  const [customerTabs, setCustomerTabs] = useState([]);
  const [showFilterForm, setShowFilterForm] = useState(false);
  const [filterDateTimeFrom, setFilterDateTimeFrom] = useState(DEFAULT_DATETIME_FROM);
  const [filterDateTimeTo, setFilterDateTimeTo] = useState(DEFAULT_DATETIME_TO);
  const [filterStatusFollowUp, setFilterStatusFollowUp] = useState(DEFAULT_STATUS);
  const [filterStores, setFilterStores] = useState([]);
  const [include, setInclude] = useState(INCLUDE_LIST[0].value);
  const [currentPageOrder, setCurrentPageOrder] = useState(1);
  const [currentPageCustomer, setCurrentPageCustomer] = useState(1);
  const [orderFilter, setOrderFilter] = useState(defaultOrderFilter);
  const [orderFollowUpFilter, setOrderFollowUpFilter] = useState(defaultOrderFollowUpFilter);

  const updateOrderFilter = (key, value) => {
    setOrderFilter({ ...orderFilter, [key]: value });
  };

  const clearOrderFilter = () => {
    setOrderFilter(defaultOrderFilter);
  };

  const updateOrderFollowUpFilter = (key, value) => {
    setOrderFollowUpFilter({ ...orderFollowUpFilter, [key]: value });
  };

  const clearOrderFollowUpFilter = () => {
    setOrderFollowUpFilter(defaultOrderFollowUpFilter);
  };

  return (
    <AdminContext.Provider
      value={{
        ALL_ORDERS,
        ALL_ORDERS_FOLLOW_UP,
        ORDERS_PER_PAGE,
        orderFilter,
        orderFollowUpFilter,
        tabs,
        user,
        customerTabs,
        showFilterForm,
        filterDateTimeFrom,
        filterDateTimeTo,
        filterStatusFollowUp,
        filterStores,
        esbQueryOrders,
        esbQueryFollowUp,
        include,
        currentPageOrder,
        currentPageCustomer,
        updateOrderFilter,
        clearOrderFilter,
        updateOrderFollowUpFilter,
        clearOrderFollowUpFilter,
        setTabs,
        setCustomerTabs,
        setShowFilterForm,
        setUser,
        setEsbQueryOrders,
        setEsbQueryFollowUp,
        setFilterDateTimeFrom,
        setFilterDateTimeTo,
        setFilterStatusFollowUp,
        setFilterStores,
        setInclude,
        setCurrentPageOrder,
        setCurrentPageCustomer,
      }}>
      {children}
    </AdminContext.Provider>
  );
};
